<template>
    <div class='sales w-full flex justify-center items-center'>
        <div class="sales_box">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            
        };
    },
    components: {
        
    },
    methods: {
        
    },
};
</script>

<style lang='scss' scoped>
.sales_box{
    width: 100%;
    max-width: var(--max-width);
}
</style>